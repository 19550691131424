import React, { useState, useEffect, useRef } from 'react'
import * as S from '../cadastro/styles'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { navigate } from "gatsby"
import cx from 'classnames'
import { ToastContainer, toast } from 'react-toastify'
import CircularProgress from '@material-ui/core/CircularProgress'
import Label from '../../../components/Form/label'
import Input from '../../../components/Form/input'
import InputMask from '../../../components/Form/mask'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import ErrorMessage from '../../../components/ErrorMessage'
import Button from '../../../components/Buttons/button'
import Checkbox from '../../../components/Form/checkbox'
import Layout from '../../../components/totem/layout'
import { ditoTrack } from '../../../helpers/dito'
import { ditoIdentify } from '../../../helpers/ditoTotem'
import {
  maskTelefone,
  maskTelefoneCO,
  maskTelefoneCL,
  maskCPF,
} from '../../../helpers/util'
import BtnVoltar from '../../../components/totem/Voltar'


const MASK = {
  br: maskTelefone,
  co: maskTelefoneCO,
  cl: maskTelefoneCL,
  cpf: maskCPF,
}

const CadastroTotem = ({ pageContext: { loja } }) => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefone, setTelefone] = useState('')
  const [cpf, setCpf] = useState('')
  const [loading, setLoading] = useState(false)
  const [erroCampos, setErroCampos] = useState({})
  const [aceiteTermosUso, setAceiteTermosUso] = useState(false)

  const [itemsCadastro, setItems] = useState([]);
  const isAuth = itemsCadastro

  function handleResetForm() {
    setNome('')
    setEmail('')
    setCpf('')
    setAceiteTermosUso(false)
    setTelefone('')
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const dados = {
      nome,
      email,
      aceiteTermosUso,
      telefone,
      cpf,
    }
    setLoading(true)
    ditoIdentify(dados)
    ditoTrack('cadastro-totem', dados)
    setErroCampos({})
    toast.success("cadasro realizado")
    handleResetForm()
    localStorage.setItem("Cadastro", true);
    redirectCadastro()
  }

  const semIndentificar= () => {
    localStorage.setItem("sem-cadastro", true);
    navigate(`/br/totem/${loja.slug}/menu`)
  };

  const redirectCadastro= () => {
    if(itemsCadastro == true){
      navigate(-1)
      localStorage.removeItem("sem-cadastro");
    }else{
      navigate(`/br/totem/${loja.slug}/menu`)
    }
  };

  useEffect(() => {
    const itemsCadastro = !!localStorage.getItem("sem-cadastro");
    setItems(itemsCadastro)
  }, []);
  

  const { t } = useTranslation()

  return (
    <Layout loja={loja}>
      <S.CadastroTotem>
        <div>
          <div className="title">
          {!isAuth ? (
            <h1>
              COMPARE PRODUTOS, TESTE SEU TÊNIS IDEAL, PEGUE DESCONTOS E MAIS!
            </h1>
          ) : (
            <h1>
              CADASTRE-SE PARA RECEBER SEU BENEFICÍIO
            </h1>
          )}
            
          </div>
          <S.FormTotem>
            <ToastContainer position="bottom-right" autoClose={5000} />
            <form onSubmit={handleSubmit}>
              <h2>Preencha os dados abaixo para iniciar</h2>
              <S.ItemForm>
                <Label texto="nome">
                  <Trans>nome</Trans>
                </Label>
                <input
                  type="text"
                  id="nome"
                  value={nome}
                  placeholder={t('name_placeholder')}
                  onChange={({ target }) => setNome(target.value)}
                  className={erroCampos.nome && 'erro'}
                />
              </S.ItemForm>
              <S.ItemForm >
                <Label texto="CPF">
                  <Trans>CPF</Trans>
                </Label>
                <InputMask
                  type="text"
                  id="CPF"
                  value={cpf}
                  placeholder="000.000.000-00"
                  onChange={({ target }) => setCpf(target.value)}
                  mask={MASK.cpf}
                />
              </S.ItemForm>
              <S.ItemForm>
                <Label texto="email">
                  <Trans>e-mail</Trans>
                </Label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  placeholder={t('email_placeholder')}
                  onChange={({ target }) => setEmail(target.value)}
                  className={erroCampos.email && 'erro'}
                />
                {erroCampos.email && <ErrorMessage mensagem={erroCampos.email} />}
              </S.ItemForm>
              <S.ItemForm>
                <Label texto="telefone">
                  <Trans>Telefone</Trans>
                </Label>
                <InputMask
                  value={telefone}
                  placeholder={t('phone_placeholder')}
                  onChange={({ target }) => setTelefone(target.value)}
                  type="tel"
                  id="telefone"
                  className={cx({ erro: erroCampos.telefone })}
                  mask={MASK.br}
                />
              </S.ItemForm>
              <S.ItemFormCheckbox>
                <Checkbox
                  id="politica"
                  name="politica"
                  value={aceiteTermosUso}
                  checked={aceiteTermosUso === true}
                  onChange={() => setAceiteTermosUso(!aceiteTermosUso)}
                />
                {erroCampos.aceiteTermosUso && (
                  <ErrorMessage mensagem={erroCampos.aceiteTermosUso} />
                )}
              </S.ItemFormCheckbox>
              <S.ItemFormCheckbox>
                <Button type="submit" disabled={!!(loading || !aceiteTermosUso)}>
                  {loading ? (
                    <>
                      <CircularProgress size={25} className="circleLoading" />{' '}
                      Enviando
                    </>
                  ) : (
                    'Continuar'
                  )}
                </Button>
              </S.ItemFormCheckbox>
            </form>
          </S.FormTotem>
          <S.BtnSemIdentificar>
            <S.NavLink 
            to={`/totem/${loja.slug}/menu`} 
            onClick={semIndentificar} 
            id="btn-menu"
            style={!isAuth ? {display: 'flex'} : {display: 'none'}}
            >
              CONTINUAR SEM ME IDENTIFICAR
            </S.NavLink>
          </S.BtnSemIdentificar>
        </div>
      </S.CadastroTotem>
    </Layout>
  )
}

CadastroTotem.propTypes = {
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default CadastroTotem

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
