import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'

export const CadastroTotem = styled.div`
    background-color: #c4ddf2;
    min-height: 101vh;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
    margin-top: -80px;
    background-position: center;
    background-size: cover;
    font-family: 'ASICSFont3.0-Regular', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px){
        padding-right: 0px;
    }

    .title{
        padding: 150px 60px 0 60px;
        text-align: center;
        h1{
            color: #001E62;
            font-style: italic;
            font-weight: 700;
            font-size: 30px;
            line-height: 32px;
        }
        @media (max-width: 767px){
            padding: 160px 60px 10px;
        }
        @media (max-width: 440px){
            padding: 110px 15px 10px;
            h1{
                font-size: 18px;
                line-height: 20px;
            }
        }
    }
`


export const FormTotem = styled.div`
        padding: 20px 60px;

        @media (max-width: 767px){
            padding: 20px 50px;
        }
        @media (max-width: 440px){
            padding: 15px 20px;
        }
        form{
            background-color: white;
            border-radius: 8px;
            padding-bottom: 30px;
            h2{
                text-align: center;
                color: #001E62;
                font-style: normal;
                font-weight: 700;
                font-size: 26px;
                line-height: 31px;
                padding-top: 40px;

                @media (max-width: 440px){
                    padding: 15px 10px;
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            @media (max-width: 440px){
                padding-bottom: 15px;
            }
        }
`
export const ItemForm = styled.div`
        padding: 15px 60px;

        @media (max-width: 440px){
            padding: 5px 25px;
        }

        label{
            font-family: 'ASICSFont3.0-Bold', sans-serif;
            color: #001E62;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 16px;
            padding-bottom: 10px;
            margin: 0;
            @media (max-width: 440px){
                font-size: 12px;
            }
        }
        input{
            border-radius: 7.196px;
            border: 0.461px solid #001E62;
            background: #FFF;
            color: #001E62;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            font-family: 'ASICSFont3.0-Regular', sans-serif;
            width: 100%;
            height: 40px;
            padding: 0 15px;
            &::placeholder {
                color: #001E62;
                font-size: 18px;
                font-style: normal !important;
                text-transform: lowercase;
                font-family: 'ASICSFont3.0-Regular' !important;
            }       
        }   
`

export const ItemFormCheckbox = styled.div`
        display: flex;
        justify-content: center;
        padding-top: 30px;
        @media (max-width: 440px){
            padding-top: 15px;
        }
        label:after{
            background-color: #001E62!important;
        }
        a{
            font-family: 'ASICSFont3.0-Bold', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 16px;
            color: #001E62 !important;
            text-decoration: underline;
            @media (max-width: 440px){
                font-size: 12px;
            }
        }

        button{
            background: #4A5FC4;
            border-radius: 55.2091px;
            padding: 15px 40px;
            &:hover{
                background-color: #001E62!important;
            }
            @media (max-width: 440px){
                font-size: 14px;
            }
        }
`

export const BtnSemIdentificar = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
    a{
        background: #001E62;
        box-shadow: 0px 0px 8.03063px rgba(0, 0, 0, 0.2);
        border-radius: 44px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        text-decoration: none;
        padding: 20px 60px;

        &:hover{
            background: #4A5FC4;
        }
        @media (max-width: 440px){
            font-size: 14px;
            padding: 15px 40px;
        }
    }
    @media (max-width: 440px){
        padding-top: 0;
        padding-bottom: 0;
    }
`

export const NavLink = styled(Link)`
    background: #001E62;
    box-shadow: 0px 0px 8.03063px rgba(0, 0, 0, 0.2);
    border-radius: 44px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;
    padding: 20px 60px;

    &:hover{
        background: #4A5FC4;
    }
`