export const SITE_ASICS_URL = {
  br: 'https://www.asics.com.br/?utm_source=lab&utm_medium=lab&utm_campaign=crm-asics-lab',
  cl: 'https://www.asics.cl/',
  co: 'https://www.asics.com.co/',
}

export const URL_PRIVACIDADE = {
  br: 'https://www.asics.com.br/politica-de-privacidade',
  cl: 'https://www.asics.cl/politica-de-privacidad',
  co: 'https://www.asics.com.co/politica-de-privacidad',
}

export const URL_LOJAS_PROXIMAS = {
  br: 'https://www.asics.com.br/ajuda/lojas',
  cl: 'https://www.asics.cl/tiendas',
  co: 'https://www.asics.com.co/sobre-asics',
}
