import { storeData, limpaObjeto} from './util'


export const ditoIdentify = ({
  nome,
  email,
  cpf,
  aceiteTermosUso,
  telefone,
}) => {
  if (window.dito) {

    const ditoUser = {
      id: window.dito.generateID(email),
      name: nome,
      email,
      cpf: cpf,
      telefone,
      optin_privacidade: aceiteTermosUso ? 'sim' : 'nao',
    }
    const newDitoUser = limpaObjeto(ditoUser)

    storeData('ditoUser', newDitoUser)
    window.dito.identify(newDitoUser)
  }
}
